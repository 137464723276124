.dashboardLayout {
  &__main {
    margin-top: $topbar-height;
    margin-left: $sidebar-width;
    padding: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .dashboardLayout {
    &__main {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboardLayout {
    &__main {
      padding: 30px 20px;
    }
  }
}
