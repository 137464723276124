.personalDetail {
  &__title {
    font-weight: 700;
    font-size: 22px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;

    & span {
      color: #58bd7d;
    }
  }

  &__disc {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    margin-bottom: 40px;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}
