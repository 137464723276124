/////// Light Variable ///////////
$bg--light: #f5f6fd;
$bg--alt--light: #fff;
$bg--alt--light2: #ededed;
$bg--alt--light3: #f5f6fd;
$text--light: #333;
$text--alt--light: #333;
$bgImageAuth--light: url(../../../image/abstractBg.png);
$bgImagePD--light: url(../../../image/f2.png);
$topBarlogoBg--light: none;
$bg--carousel--light: rgb(255, 255, 255, 0.9);
$referalIconBg--light: rgba(0, 0, 0, 0.2);

/////// Dark Variable ///////////

$bg--dark: #111114;
$bg--alt--dark: #171b23;
$bg--alt--dark2: #202531;
$bg--alt--dark3: rgba(32, 37, 49, 0.5);
$text--dark: #b1b5c3;
$text--alt--dark: #fff;
$bgImageAuth--dark: url(../../../image/abstractBg.png),
  url(../../../image/svg/authbg.svg);
$topBarlogoBg--dark: linear-gradient(
  90deg,
  rgba(55, 114, 255, 0.2) 0%,
  rgba(55, 114, 255, 0) 100%
);
$bg--carousel--dark: rgba(29, 31, 37, 0.698);
$referalIconBg--dark: rgba(255, 255, 255, 0.1);
$bgImagePD--dark: url(../../../image/f3.png);

$themes: (
  light: (
    bg: $bg--light,
    bgAlt: $bg--alt--light,
    bgAlt2: $bg--alt--light2,
    bgAlt3: $bg--alt--light3,
    text: $text--light,
    textAlt: $text--alt--light,
    bgAuth: $bgImageAuth--light,
    bgPD: $bgImagePD--light,
    topBarlogoBg: $topBarlogoBg--light,
    bgCarousel: $bg--carousel--light,
    referalIconBg: $referalIconBg--light,
  ),
  dark: (
    bg: $bg--dark,
    bgAlt: $bg--alt--dark,
    bgAlt2: $bg--alt--dark2,
    bgAlt3: $bg--alt--dark3,
    text: $text--dark,
    textAlt: $text--alt--dark,
    bgAuth: $bgImageAuth--dark,
    bgPD: $bgImagePD--dark,
    topBarlogoBg: $topBarlogoBg--dark,
    bgCarousel: $bg--carousel--dark,
    referalIconBg: $referalIconBg--dark,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
