.programsDetails {
  background-image: url(../../../image/f3.png);
  @include themed() {
    background-image: t("bgPD");
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
  gap: 10%;
  row-gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  & h3 {
    font-weight: 700;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }
  }

  & p {
    font-weight: 400;
    font-size: 12px;
  }

  &__bonus,
  &__att {
    display: flex;
    align-items: center;
    gap: 13px;

    &__img {
      @include flex-center;
      width: 45px;
      height: 45px;
      @include themed() {
        background-color: t("referalIconBg");
      }
      border-radius: 50%;
    }
  }
}
