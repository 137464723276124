.apperances {
  &__title {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }
  }

  &__mode {
    border-bottom: 1px solid rgb(127, 127, 127);
    margin-bottom: 24px;

    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      &__left {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }
}
