.about {
  background-color: #f7f7f7;
  //   padding: 100px 0;

  &__main {
    color: #fff;
    background-image: url(../../../../image/aboutL.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 7%;
    padding-bottom: 200px;
    position: relative;
    margin-bottom: 50px;

    &__title {
      font-weight: 700;
      font-size: 48px;
      text-align: center;
      color: #f1c767;
    }

    &__curve {
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 40px;
      text-align: center;

      margin-bottom: 80px;
    }

    &__disc {
      padding: 60px 30px;
      background: linear-gradient(
        104.74deg,
        rgba(24, 37, 90, 0.33) -3.76%,
        rgba(14, 15, 32, 0.29) 54.16%,
        rgba(219, 172, 62, 0.03) 255.17%
      );
      border-radius: 16px;
      margin-bottom: 120px;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      font-family: "DM Sans", sans-serif;

      border: 3px solid #e3b447a5;
      border-radius: 16px;
    }
  }

  & button {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 45px;
    border-radius: 50px;
    border: none;
    background: linear-gradient(
      90deg,
      #b88717 -5.35%,
      #ffd166 21.73%,
      #f5d281 42.92%,
      #c48e0e 107.68%
    );
  }

  &__list {
    padding: 0 7%;
  }

  &__programsList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 140px;

    &__item {
      background: #fff;
      border-radius: 16px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      &__left {
        // width: 80%;
        & h4 {
          min-height: 88px;
          padding: 8px 16px;
          background: #ffefcc;
          border-radius: 8px;
          font-weight: 400;
          font-size: 24px;

          & b {
            font-weight: 600;
          }

          margin-bottom: 16px;
        }

        & p {
          font-weight: 400;
          font-size: 20px;
          margin-bottom: 30px;
        }
      }

      &__right {
        display: flex;
        justify-content: flex-end;
        & img {
          margin-top: 10px;
          //   width: 186px;
        }
      }
    }
  }

  &__programsFullList {
    padding-bottom: 100px;

    &__item {
      background-image: url(../../../../image/pillar.png);
      &.alt {
        background-image: url(../../../../image/pillarAlt.png);
      }
      background-repeat: no-repeat;
      background-position: right bottom;
      &__main {
        max-width: 70%;
      }
      margin: 0 auto;
      background-color: #f0f1ff;
      border-radius: 16px;
      padding: 80px 40px;
      //   max-width: 950px;

      font-size: 20px;
      font-family: "DM Sans", sans-serif;
      line-height: 40px;

      &:not(:last-child) {
        margin-bottom: 70px;
      }

      & h4 {
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 10px;
      }

      & h3 {
        background: linear-gradient(
          90deg,
          #b88717 -5.35%,
          #c4990e 27.17%,
          #c4990e 45.86%,
          #ffd166 107.68%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 20px;
      }

      & b {
        font-weight: 700;
      }
      & p {
        padding: 20px 0;
      }

      & ul {
        list-style: disc;
        padding-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .about {
    &__programsFullList {
      &__item {
        &__main {
          max-width: 60%;
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .about {
    &__programsFullList {
      &__item {
        padding-bottom: 400px;
        // background-image: none;
        background-position: center bottom;
        background-size: 300px;
        &.alt {
          background-position: center bottom;
        }

        &__main {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .about {
    &__programsList {
      &__item {
        &__left {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .about {
    &__programsList {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 600px) {
  .about {
    &__main {
      &__title {
        font-size: 40px;
      }

      &__curve {
        height: 150px;
      }

      &__subtitle {
        font-size: 35px;
      }

      &__disc {
        font-size: 18px;
      }
    }
    &__programsFullList {
      &__item {
        padding: 80px 20px;
        padding-bottom: 400px;
        font-size: 17px;
      }
    }
  }
}
