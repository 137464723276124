.barrs {
  background: #f1f1f4;
  padding: 100px 7%;

  &__main {
    background-color: #0e0f1f;
    background-image: url(../../../../image/BARRS.png);
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;
    border-radius: 16px;
    display: flex;
    padding: 50px;
    color: #fff;

    &__left {
      max-width: 55%;

      & h1 {
        background: linear-gradient(
          90deg,
          #b88717 -5.35%,
          #ffd166 21.73%,
          #f5d281 42.92%,
          #c48e0e 107.68%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 16px;
      }

      & p {
        font-weight: 400;
        font-size: 20px;
      }

      & button {
        margin-top: 30px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 45px;
        border-radius: 50px;
        position: relative;
        border: none;
        background: linear-gradient(
          90deg,
          #b88717 -5.35%,
          #ffd166 21.73%,
          #f5d281 42.92%,
          #c48e0e 107.68%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        &::before {
          cursor: pointer;
          content: "";
          position: absolute;
          inset: 0;
          border-radius: 50px;
          padding: 1px;
          background: linear-gradient(
            90deg,
            #b88717 -5.35%,
            #ffd166 21.73%,
            #f5d281 42.92%,
            #c48e0e 107.68%
          );
          mask: linear-gradient(#191a38 0 0) content-box,
            linear-gradient(#191a38 0 0);
          -webkit-mask: linear-gradient(#191a38 0 0) content-box,
            linear-gradient(#191a38 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .barrs {
    &__main {
      background-image: none;

      &__left {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .barrs {
    &__main {
      padding: 50px 20px;
    }
  }
}
