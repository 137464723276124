.balance {
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }

    &__img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: #5960ee27;
      @include flex-center;
    }
  }
  &__info {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
    margin-bottom: 30px;

    &__programs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      &__item {
        @include themed() {
          background-color: t("bgAlt");
        }
        border-radius: 10px;
        min-height: 130px;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 20px;

        & img {
          width: 100px;
        }

        &__main {
          & h3 {
            font-weight: 700;
            font-size: 15px;
            @include themed() {
              color: t("textAlt");
            }
          }

          &__bal {
            display: flex;
            align-items: center;
            column-gap: 5px;
            @include themed() {
              color: t("textAlt");
            }
            font-weight: 700;
            font-size: 18px;
            flex-wrap: wrap;
            margin: 5px 0;

            & span {
              font-weight: 800;
              font-size: 12px;
              width: 40px;
              height: 26px;
              background: #58bd7d;
              border-radius: 4px;
              @include flex-center;
            }
          }
        }
      }
    }

    &__uwl {
      box-shadow: 0px 20px 40px rgba(91, 72, 167, 0.4);
      background: linear-gradient(112.7deg, #5a61f0 -6.23%, #8144a4 114.08%);
      border-radius: 15px;
      // @include themed() {
      //   color: t("textAlt");
      // }
      color: #fff;
      padding: 20px;
      max-height: 300px;

      & h3 {
        font-weight: 800;
        font-size: 20px;
        margin-bottom: 10px;
      }

      &__disc {
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;

        & h4 {
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #a5a5ff;
          margin-bottom: 10px;
        }

        & p {
          margin-bottom: 10px;
        }
        &__img {
          height: 150px;
        }
      }

      &__link {
        @include flex-center;
        margin-top: 20px;
        width: 250px;
        height: 45px;
        border-radius: 20px;
        border: 1px solid #fff;
        gap: 10px;
        color: #fff;
        cursor: pointer;

        // @include themed() {
        //   color: t("textAlt");
        // }
        font-weight: 700;
        font-size: 15px;
      }
    }
  }

  &__cfc {
    // background-image: url(../../../image/uttaCoinAlt.png);
    background-position: right;
    background-repeat: no-repeat;
    border-radius: 16px;
    min-height: 280px;
    @include themed() {
      background-color: t("bgAlt");
    }
    margin-bottom: 30px;
    padding: 30px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__img {
      // width: 150px;
      height: 200px;
    }

    &__main {
      & h2 {
        font-weight: 400;
        font-size: 18px;
      }

      & h3 {
        font-weight: 800;
        font-size: 50px;
        @include themed() {
          color: t("textAlt");
        }
        display: flex;

        & span {
          color: $text-color;
          font-weight: 400;
          font-size: 12px;
        }
      }

      &__status {
        font-weight: 400;
        font-size: 14px;
        color: #58bd7d;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        gap: 8px;
      }
    }
  }

  &__other {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
    gap: 30px;

    &__left,
    &__right {
      @include themed() {
        background-color: t("bgAlt");
      }
      border-radius: 12px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__disc {
        &__header {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 20px;

          & span {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #4bc9f0;
            border-radius: 4px;
          }
        }

        &__amount {
          & h4 {
            font-weight: 700;
            font-size: 32px;
            @include themed() {
              color: t("textAlt");
            }
          }

          & p {
            font-weight: 600;
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .balance {
    &__info {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 750px) {
  .balance {
    &__other {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 600px) {
  .balance {
    &__cfc {
      &__img {
        display: none;
      }
    }
    &__info {
      &__programs {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }
  }
}

// @media screen and (max-width: 420px) {
//   .balance {
//     &__info {
//       &__programs {
//         grid-template-columns: 1fr;
//       }
//     }
//   }
// }
