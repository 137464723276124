.blogPage {
  padding: 65px 7%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;
  }

  &__main {
    max-width: 1031px;
  }

  &__latest {
    margin-bottom: 130px;
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      //   height: 270px;

      &__left {
        & img {
          width: 100%;
          height: 270px;
          object-fit: cover;
          border-radius: 16px;
        }
      }

      &__right {
        & p {
          margin-bottom: 16px;
          font-weight: 400;
          font-size: 18px;
        }

        & h3 {
          font-weight: 700;
          font-size: 35px;
          line-height: 50px;
        }
      }
    }
  }
  &__recent {
    margin-bottom: 100px;

    &__list {
      &__item {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        margin-bottom: 40px;
        //   height: 270px;

        &__left {
          & img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        &__right {
          & p {
            margin-bottom: 16px;
            font-weight: 400;
            font-size: 18px;
          }

          & h3 {
            font-weight: 600;
            font-size: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .blogPage {
    &__latest {
      &__item {
        grid-template-columns: 1fr;
      }
    }

    &__recent {
      &__list {
        &__item {
          margin-bottom: 70px;
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blogPage {
    &__latest {
      &__item {
        &__right {
          & h3 {
            line-height: 40px;
            font-size: 30px;
          }
        }
      }
    }
  }
}
