.payment {
  margin-top: 25px;
  @include themed() {
    background-color: t("bgAlt");
  }
  border-radius: 10px;
  padding: 20px;

  &__header {
    @include flex-center-space;
    margin-bottom: 25px;
    gap: 20px;

    &__title {
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      @include themed() {
        color: t("textAlt");
      }
    }
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 18px;

    &__radio {
      border-radius: 5px;
      padding: 20px;
      @include themed() {
        background-color: t("bgAlt3");
      }
      // background: rgba(32, 37, 49, 0.5);

      & h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        @include themed() {
          color: t("textAlt");
        }
        margin-bottom: 25px;
      }

      &__main {
        display: flex;
        gap: 35px;
        //   align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
      }
    }

    &__amount {
      & h5 {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        @include themed() {
          color: t("textAlt");
        }
        // margin-bottom: 25px;
      }
      border-radius: 5px;
      padding: 20px;
      @include themed() {
        background-color: t("bgAlt3");
      }
      // background: rgba(32, 37, 49, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      &__inputs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        gap: 30px;

        &__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          z-index: 1;
          background-color: $bg-color-alt2;
          @include flex-center;
        }

        &__token {
          height: 38px;
          background: $bg-color-alt2;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          font-weight: 700;
          font-size: 12px;
          line-height: 19px;
          @include themed() {
            color: t("textAlt");
          }
        }

        &__usd {
          height: 38px;
          position: relative;
          background-color: $bg-color-alt2;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          & span {
            top: 0;
            display: inline-block;
            position: absolute;
            @include themed() {
              color: t("textAlt");
            }
            height: 100%;
            right: 0;
            padding: 8px 15px;
          }
        }

        &__coin {
          position: relative;

          & span {
            display: inline-block;
            position: absolute;
            border-left: 1px solid #000;
            @include themed() {
              color: t("textAlt");
            }
            height: 100%;
            right: 0;
            padding: 8px 15px;
          }
          & div {
            position: absolute;
            right: 80px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
          }

          &__main {
            width: 100%;
            height: 38px;
            @include themed() {
              background-color: t("bgAlt");
            }
            // background: #202531;
            border-radius: 5px;
            border: none;
            padding: 15px 20px;
            @include themed() {
              color: t("textAlt");
            }

            &:focus {
              border: none;
              outline: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .payment {
    &__form {
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (max-width: 600px) {
  .payment {
    &__header {
      @include flex-center-space;
      flex-direction: column;

      &__title {
        text-align: center;
        font-size: 18px;
      }
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 18px;

      &__amount {
        &__inputs {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
