.carousel {
  background-image: url(../../../image/Group1202.png),
    url(../../../image/Group1184.png);
  background-position: left bottom, right top;
  background-repeat: no-repeat;
  // min-height: 397px;
  @include themed() {
    background-color: t("bgCarousel");
  }
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 100px;

  &__main {
    margin: 0 auto;
    width: 95%;
    height: 180px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
    transition: all 0.3s linear;

    &.program {
      height: auto;
    }

    & .article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s linear;
    }

    & article.activeSlide {
      opacity: 1;
      transform: translateX(0);
    }
    & article.lastSlide {
      transform: translateX(-100%);
    }
    & article.nextSlide {
      transform: translateX(100%);
    }

    &__title {
      font-weight: 700;
      font-size: 32px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 10px;

      & span {
        color: $secondary-color;
      }
    }
    &__disc {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }

  &__static {
    margin-top: 10px;

    &__disc {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 40px;
    }

    &__countDown {
      @include flex-center;
      gap: 28px;

      &__item {
        @include themed() {
          background-color: t("bg");
        }
        padding: 5px;
        border-radius: 10px;
        height: 100px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        width: 85px;

        & h3 {
          font-weight: 800;
          font-size: 32px;
          // line-height: 44px;
          background: $primary-gradient-color;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

        & span {
          font-weight: 700;
          font-size: 16px;
          // line-height: 22px;
        }
      }
    }
  }

  &__indicator {
    margin-top: 50px;
    display: flex;
    gap: 5px;

    &__item {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $primary-gradient-color;
      opacity: 0.5;

      &.active {
        width: 25px;
        border-radius: 20px;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .carousel {
    &__main {
      height: 250px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .carousel {
    &__main {
      height: 200px;
    }
  }
}

@media screen and (max-width: 850px) {
  .carousel {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 750px) {
  .carousel {
    background-image: none;

    padding: 20px 20px;
    &__main {
      height: 230px;
    }
  }
}

@media screen and (max-width: 650px) {
  .carousel {
    background-image: none;

    padding: 20px 20px;
    &__main {
      &__title {
        font-size: 25px;
      }
      &__disc {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .carousel {
    &__static {
      &__countDown {
        &__item {
          height: 80px;
          width: 70px;
          & h3 {
            font-size: 20px;
          }

          & span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .carousel {
    // &__main {
    //   height: 280px;
    // }
    &__static {
      &__countDown {
        flex-wrap: wrap;
        row-gap: 10px;

        &__item {
          height: 80px;
          width: 70px;
          & h3 {
            font-size: 20px;
          }

          & span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .carousel {
    &__main {
      height: 280px;
    }
  }
}

@media screen and (max-width: 350px) {
  .carousel {
    &__main {
      height: 320px;
    }
  }
}
