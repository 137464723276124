@import "themes/theme";

@import "abstracts/variables";
@import "abstracts/mixins";

@import "layout/authLayout";
@import "layout/dashboardLayout";
@import "layout/formLayout";
@import "layout/landingLayout";

@import "components/topbar";
@import "components/sidebar";
@import "components/authForm";
@import "components/carousel";
@import "components/programsInfo";
@import "components/subscribeChannel";
@import "components/reactivateModal";
@import "components/invoice";
@import "components/paymentSuccess";
@import "components/payment";
@import "components/chart";
@import "components/drawer";
@import "components/applicationResponse";
@import "components/profile";
@import "components/apperances";
@import "components/notification";
@import "components/session";
@import "components/oneStepId";
@import "components/walletModal";
@import "components/eligibilityModal";
@import "components/transactionHistory";
@import "components/paymentCongratModal";
@import "components/programsDetails";
@import "components/wemaModal.";
@import "components/biometricRegisterModal";
@import "components/landingPage/navBar";
@import "components/landingPage/hero";
@import "components/landingPage/whatWeOffer";
@import "components/landingPage/mission";
@import "components/landingPage/overView";
@import "components/landingPage/about";
@import "components/landingPage/cfc";
@import "components/landingPage/chartL";
@import "components/landingPage/barrs";
@import "components/landingPage/wealth";
@import "components/landingPage/footer";
@import "components/landingPage/blog";
@import "components/landingPage/drawerL";
@import "components/profileMain/profileComplete";
@import "components/profileMain/kyc";
@import "components/profileMain/personalDetail";
@import "components/profileMain/verify";
@import "components/profileMain/selfieWithId";
@import "components/profileMain/verificationStatus";
@import "components/getHelp";
@import "components/getHelpBio";

@import "pages/login.scss";
@import "pages/home.scss";
@import "pages/refer.scss";
@import "pages/submit.scss";
@import "pages/referral.scss";
@import "pages/leaderboard.scss";
@import "pages/balance.scss";
@import "pages/balance.scss";
@import "pages/introducer.scss";
@import "pages/beneficiary.scss";
@import "pages/recovery.scss";
@import "pages/settings.scss";
@import "pages/creaditLine.scss";
@import "pages/exchange.scss";
@import "pages/cashForCharity.scss";
@import "pages/wallet.scss";
@import "pages/homepage.scss";
@import "pages/blogPage.scss";
@import "pages/blogItemPage.scss";
@import "pages/faqPage.scss";
@import "pages/faqItemPage.scss";
@import "pages/helpCentre.scss";

:root {
  font-size: 62.5%;
  --body-padding: 10%;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 1.6rem;
  font-family: "Nunito", sans-serif;
}
.app {
  @include themed() {
    color: t("text");
    background-color: t("bg");
  }
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
select {
  color: $text-color;
  font-family: Work Sans, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

// .btnAlt {
//   border: none;
//   outline: none;
//   width: 166px;
//   height: 40px;
//   background: linear-gradient(87.74deg, #e7a547 1.89%, #e2c75b 96.74%);
//   border-radius: 30px;
//   cursor: pointer;
// }

// .btnMain {
//   border: none;
//   outline: none;
//   width: 324px;
//   height: 48px;
//   background: #fccf6f;
//   border-radius: 30px;
//   cursor: pointer;
// }

///////////// react-phone-input-2 /////////////
.form-control {
  padding-left: 80px !important;
  @include themed() {
    color: t("textAlt");
    background-color: t("bg") !important;
  }
  // background-color: $bg-color-alt !important;
  // color: #fff;
  height: 55px !important;
  width: 100% !important;
  border: 1px solid rgba(157, 165, 178, 0.5) !important;
}
.react-tel-input {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.flag-dropdown {
  display: flex;
  justify-content: center;
  width: 60px !important;
  @include themed() {
    background-color: t("bgAlt") !important;
  }
  // background-color: #1b1f28 !important;
  border: 1px solid rgba(157, 165, 178, 0.5) !important;
  border-right: none !important;
}

.flag-dropdown.open {
  background-color: transparent !important;
}
.selected-flag:hover,
.react-tel-input,
.selected-flag:focus {
  background-color: transparent !important;
}

.react-select__control {
  @include themed() {
    background-color: t("bgAlt") !important;
  }
  // background-color: #202531 ;
  border: none !important;
}

.react-select__single-value {
  @include themed() {
    color: t("textAlt") !important;
  }
  // color: #fff !important;
}

/////////// MODAL STYLES ////////////

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.modal-overlay-alt {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .modal-overlay {
    left: 0;
  }
}

/////////// PAGINATE ////////////

.paginationBttns {
  /* height: 20px; */
  list-style: none;
  display: flex;
  // align-self: flex-end;
  justify-content: center;
}

.paginationBttns a {
  height: 33px;
  width: 35px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  @include themed() {
    background-color: t("bgAlt2");
  }
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.paginationBttns a:hover {
  color: white;
}

.paginationActive a {
  color: white;
  background-color: $primary-color !important;
}

.paginationDisabled a {
  color: #868686;
  background-color: transparent;
}
.pignateCon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/////////// REACT-TOGGLE ////////////

.react-toggle--checked .react-toggle-track {
  background-color: $primary-color !important;
}
.react-toggle--checked .react-toggle-thumb {
  border-color: $primary-color !important;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

//////// Swiper ////////////

.swiper {
  width: 100%;
}

// .swiper-slide {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   -webkit-align-items: center;
//   align-items: center;
//   // width: 500px !important;
// }

// .swiper-slide img {
//   border-radius: 20px;
//   display: block;
//   // height: 100%;
//   object-fit: cover;
// }

.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

.swiperBtn {
  display: flex;
  gap: 3px;

  margin-top: 40px;
  &__prev,
  &__next {
    @include flex-center;
    background: #fff;
    width: 60px;
    height: 60px;
    font-size: 30px;
    cursor: pointer;
  }

  &__prev {
    border-radius: 30px 0px 0px 30px;
    box-shadow: 0px 10px 45px rgba(86, 93, 236, 0.1);
  }
  &__next {
    border-radius: 0px 30px 30px 0px;
    box-shadow: 0px 10px 45px rgba(86, 93, 236, 0.1);
  }
}

@media screen and (max-width: 1000px) {
  .modal-overlay {
    left: 0;
  }
}
@media screen and (max-width: 900px) {
  :root {
    font-size: 62.5%;
    --body-padding: 8%;
  }
}

@media screen and (max-width: 600px) {
  .btnMain {
    border: none;
    outline: none;
    width: 250px;
    height: 48px;
    background: #fccf6f;
    border-radius: 30px;
    cursor: pointer;
  }
  :root {
    font-size: 62.5%;
    --body-padding: 5%;
  }
}

@media screen and (max-width: 400px) {
  .btnMain {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  :root {
    font-size: 62.5%;
    --body-padding: 3%;
  }
}

//////// CircularProgressbar ////////////

.CircularProgressbar-path {
  stroke: #efc262 !important;
}
.CircularProgressbar-trail {
  stroke: #292d32 !important;
}
.CircularProgressbar-text {
  @include themed() {
    fill: t("textAlt") !important;
  }
}

//////// react-html5-camera-photo ////////////

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  // object-fit: cover;
  // height: 100% !important;
  width: 100% !important;
}
#container-circles {
  position: absolute;
  left: 95% !important;
  bottom: 60% !important;
}
#outer-circle {
  height: 50px !important;
  width: 50px !important;
}

#inner-circle {
  height: 30px !important;
  width: 30px !important;
  margin: -27px 0 0 -15px !important;
}

//////// react-time-picker ////////////

.react-time-picker {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: #232324;
  padding: 0 10px;
}
.react-time-picker__wrapper {
  border: none !important;
}
