.overView {
  background-color: #111223;
  background-image: url(../../../../image/overview.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 35%;
  padding: 80px 7%;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;

  &__header {
    padding-top: 100px;
    &__title {
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 48px;
    }

    &__subtitle {
      font-weight: 500;
      font-size: 32px;
      max-width: 900px;
      margin-bottom: 20px;
    }
  }

  &__list {
    &__item {
      background: linear-gradient(
        104.74deg,
        rgba(24, 37, 90, 0.33) -3.76%,
        rgba(14, 15, 32, 0.29) 54.16%,
        rgba(219, 172, 62, 0.03) 255.17%
      );
      border: 3px solid #e3b447a5;
      border-radius: 16px;
      padding: 40px 90px;
      line-height: 40px;
      font-weight: 400;
      font-size: 20px;
      font-family: "DM Sans", sans-serif;
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 950px) {
  .overView {
    // padding-bottom: 500px;
    background-image: none;
    grid-template-columns: 1fr;
    &__header {
      padding-top: 0;
    }

    &__list {
      &__item {
        padding: 40px 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .overView {
    &__list {
      &__item {
        font-size: 18px;
        padding: 40px 20px;
      }
    }
  }
}
