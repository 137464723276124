.leaderboard {
  &__check {
    height: 306px;
    background: url(../../../image/leaderboard.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;

    &__title {
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      color: #ffffff;
      margin-bottom: 12px;
    }
  }

  &__filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    & select {
      height: 50px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      @include themed() {
        background: t("bgAlt");
      }
      // background: $bg-color-alt;
      border: 0.946154px solid rgba(63, 63, 86, 0.34);
      border-radius: 0;

      &.last {
        border-radius: 0px 10.4077px 10.4077px 0px;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
    &__search {
      height: 50px;

      position: relative;
      & input {
        padding-left: 50px;
        padding-right: 20px;
        width: 100%;
        height: 100%;
        @include themed() {
          background: t("bgAlt");
        }
        // background: $bg-color-alt;
        border: 0.946154px solid rgba(63, 63, 86, 0.34);
        border-radius: 10.4077px 0px 0px 10.4077px;

        &:focus {
          border: none;
          outline: none;
        }
      }
      & img {
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }

    &__earning {
      position: relative;

      & select {
        padding-left: 90px;
      }

      & span {
        font-size: 15px;
        @include themed() {
          color: t("textAlt");
        }
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
      }
    }
  }

  &__table {
    margin-top: 30px;
    overflow: auto;
    padding-bottom: 20px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 830px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 55px;
      @include themed() {
        background-color: t("bgAlt");
      }
      border: 0.838636px solid rgba(255, 255, 255, 0.05);

      &:first-child {
        border-radius: 9.225px 9.225px 0px 0px;
      }

      // &:last-child {
      //   border-radius: 0px 0px 9.225px 9.225px;
      //   background: $primary-gradient-color;
      //   color: #ffffff;
      // }

      &__head {
        font-weight: 700;
        font-size: 13.4182px;
        line-height: 20px;
        text-transform: uppercase;
        @include themed() {
          color: t("textAlt");
        }

        &__num {
          width: 55px;
          display: flex;
          justify-content: center;
        }
      }

      &__data {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &__iconCon {
          display: flex;
          gap: 10px;
        }

        &__icon {
          display: flex;
          align-items: center;
          gap: 8px;

          & img {
            border-radius: 50%;
            width: 35px;
            height: 35px;
          }
        }

        &__btn {
          width: 82px;
          height: 31px;
          background: rgba(47, 180, 255, 0.2);
          border-radius: 5px;
          border: none;
          color: #2fb4ff;
          cursor: pointer;

          &.subscribed {
            background: rgba(44, 207, 84, 0.2);
            color: #2ccf54;
          }
        }
        &__num {
          width: 55px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .leaderboard {
    &__filter {
      gap: 5px;
      grid-template-columns: repeat(2, 1fr);
      & select {
        border-radius: 10.4077px;

        &:last-child {
          border-radius: 10.4077px;
        }
      }
      &__search {
        & input {
          border-radius: 10.4077px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .leaderboard {
    &__filter {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 450px) {
  .leaderboard {
    &__check {
      padding: 20px;
    }
  }
}
