.sidebar {
  z-index: $zIndex;
  top: $topbar-height;
  position: fixed;
  width: $sidebar-width;
  height: calc(100vh - $topbar-height);
  @include themed() {
    background-color: t("bgAlt");
  }
  overflow: auto;

  &__list {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__item {
      display: flex;
      align-items: center;
      padding: 8px 20px;
      gap: 15px;
      position: relative;
      @include themed() {
        color: t("text");
      }

      &.active {
        @include themed() {
          color: t("textAlt");
        }
        // color: white;
        background: linear-gradient(
          90deg,
          rgba(55, 114, 255, 0.2) 0%,
          rgba(55, 114, 255, 0) 100%
        );

        & span {
          display: inline-block;
          height: 100%;
          width: 4px;
          background: #3772ff;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    &__line {
      height: 1px;
      background: #b1b5c3;
      width: calc(100% - 40px);
      margin: 0 20px;
      margin-top: 20px;
    }
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }

  .sidebar.drawerOpen {
    display: block;
    border-top: 1px solid $bg-color;
    width: 95%;
    max-width: 500px;
  }
}
