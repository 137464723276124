.home {
  // &__title {
  //   font-weight: 800;
  //   font-size: 32px;
  //   line-height: 44px;
  //   @include themed() {
  //     color: t("text");
  //   }
  //   margin-bottom: 20px;
  // }

  &__programs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 28px;
    row-gap: 35px;
    margin-top: 25px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      padding: 20px;
      @include themed() {
        background-color: t("bgAlt");
      }

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 124%;
        @include themed() {
          color: t("textAlt");
        }
      }

      &__subTitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        background: $primary-gradient-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 10px;
      }

      &__disc {
        font-weight: 300;
        font-size: 15px;
        // line-height: 16px;
        // letter-spacing: 1px;
      }

      &__btn {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home {
    &__programs {
      grid-template-columns: 1fr;
    }
  }
}
