.formLayout {
  padding: 30px;

  &__header {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  .formLayout {
    padding: 15px;
  }
}
