.wemaModal {
  width: 75vw;
  height: 95vh;
  background-color: #020202;
  overflow: auto;
  border-radius: 16px;
  border: 1px solid #eaa613;
  padding: 20px;
  position: relative;
  cursor: pointer;
  //   margin: 0 5%;
  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &__header {
    background-image: url(../../../image/gliter2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    &__title {
      color: #fff;
      font-weight: 800;
      font-size: 40px;
      text-align: center;
      margin-bottom: 10px;
    }

    & img {
      height: 190px;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    &__left,
    &__right {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      border-radius: 16px;
      font-size: 14px;

      &__title {
        margin-bottom: 10px;
      }

      &__disc {
        text-align: center;
      }
    }

    &__left {
      background: #626262;
      text-align: center;
      background-image: url(../../../image/coinsArr.png);
      background-position: bottom left;
      background-repeat: no-repeat;

      &__programs {
        margin-top: 10px;

        &__btns {
          background: #373737;
          border-radius: 8px;
          padding: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          column-gap: 40px;

          & p {
            margin-bottom: 5px;
          }
        }
      }
    }

    &__right {
      background: #222325;
      background-image: url(../../../image/coins3.png);
      background-position: bottom right;
      background-repeat: no-repeat;

      & ol {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wemaModal {
    width: 95vw;
  }
}

@media screen and (max-width: 1000px) {
  .wemaModal {
    &__main {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 500px) {
  .wemaModal {
    &__main {
      &__left {
        &__programs {
          &__btns {
            grid-template-columns: 1fr;
            justify-items: center;
          }
        }
      }
    }
  }
}
