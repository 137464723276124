.submit {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;
  }
}
