.settings {
  display: flex;
  gap: 15px;

  &__left {
    width: 220px;
    &__links {
      //   display: inline-block;

      &__line {
        height: 1px;
        background: #b1b5c3;
        margin-bottom: 25px;
      }

      &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        gap: 10px;
        color: #777e91;

        &.active {
          @include themed() {
            color: t("textAlt");
          }
        }
      }
    }

    &__circle {
      margin-top: 50px;
      width: 80%;
      @media screen and (max-width: 750px) {
        display: none;
      }

      & p {
        margin-top: 10px;
        text-align: center;
        font-weight: 800;
        @include themed() {
          color: t("textAlt");
        }
      }
    }
  }

  &__right {
    min-height: 500px;
    width: calc(100% - 220px);
    @include themed() {
      background: t("bgAlt");
    }
    border-radius: 16px;
    padding: 3%;

    &__user {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;

      &__img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #787878;
        @include flex-center;
        overflow: hidden;

        & img {
          width: 60px;
        }
      }

      &__info {
        & h3 {
          font-weight: 700;
          font-size: 24px;
          @include themed() {
            color: t("textAlt");
          }
          display: flex;
          align-items: center;
          gap: 10px;

          & span {
            font-weight: 700;
            font-size: 15px;
            color: #58bd7d;
            display: inline-block;
            width: 70px;
            border: 1px solid #676f86;
            border-radius: 20px;
            padding: 5px 10px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .settings {
    flex-direction: column;
    &__left {
      width: 100%;
      &__links {
        margin-bottom: 10px;
        display: flex;
        overflow: auto;
        // flex-wrap: wrap;
        gap: 20px;
        &__line {
          display: none;
        }

        &__item {
          margin-bottom: 0px;
          // display: inline-block;
          flex-direction: column;
        }
      }
    }
    &__right {
      width: 100%;
    }
  }
}
