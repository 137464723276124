.walletModal {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg-color-alt;
  padding: 30px 20px;
  border-radius: 16px;
  position: relative;

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &__title {
    font-weight: 800;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }

  &__img {
    margin-bottom: 16px;
    width: 150px;
  }

  &__walletType {
    &__title {
      font-weight: 700;
      color: #fff;
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__main {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      justify-content: center;
      gap: 10px;

      & img {
        width: 25px;
      }
    }
  }

  &__address {
    & h5 {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 16px;
    }

    &__main {
      height: 48px;
      padding: 0 23px;
      display: flex;
      align-items: center;
      background-color: $bg-color-alt2;
      border-radius: 16px;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
    }
  }

  &__line {
    height: 1px;
    background: #4d4d4d;
    width: 100%;
    margin-top: 20px;
  }

  &__btnCon {
    margin-top: 20px;
    display: flex;
    gap: 30px;
  }
}

@media screen and (max-width: 750px) {
  .walletModal {
    width: 95%;
    margin: 0 2.5%;
  }
}
@media screen and (max-width: 600px) {
  .walletModal {
    &__btnCon {
      flex-direction: column;
    }
  }
}
