.transactionHistory {
  @include themed() {
    background-color: t("bgAlt");
  }
  border-radius: 10px;
  margin-bottom: 20px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    & h2 {
      font-weight: 700;
      font-size: 24px;
      @include themed() {
        color: t("textAlt");
      }
    }

    &__btnCon {
      display: flex;
      gap: 10px;

      &__item {
        width: 70px;
        height: 32px;
        border-radius: 9.67091px;
        border: none;
        @include themed() {
          background-color: t("bgAlt2");
        }
        @include themed() {
          color: t("text");
        }
        &.active {
          background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
          color: white;
        }
      }
    }
  }

  &__table {
    overflow: auto;
    padding-bottom: 40px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 800px;
    }

    &__row {
      gap: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      height: 55px;
      @include themed() {
        background-color: t("bgAlt");
      }

      &:nth-child(even) {
        @include themed() {
          background-color: t("bgAlt2");
        }
      }

      &__head {
        font-size: 14px;
      }

      &__data {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        @include themed() {
          color: t("textAlt");
        }
      }
    }
  }
}
