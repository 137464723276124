.footer {
  //   padding: 80px 0;
  color: #fff;
  background: #0e0f1f;
  text-align: center;
  position: relative;
  z-index: 2;

  &__main {
    padding: 80px 7%;
    background-image: url(../../../../image/svg/CTA.svg);
    //   background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 70px;

    & h2 {
      font-weight: 600;
      font-size: 24px;
    }
    & h3 {
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 48px;
      margin-bottom: 30px;
    }

    & p {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 50px;
      max-width: 850px;
    }

    &__socialLinks {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      gap: 10px;

      &__item {
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        border-radius: 5px;
        height: 35px;
        width: 35px;
        cursor: pointer;
      }
    }
  }

  &__bottom {
    padding: 60px 7%;
    padding-bottom: 0;
    background-image: url(../../../../image/waveO.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    & p {
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 40px;
    }

    & ul {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 5%;
      justify-content: center;
      padding-bottom: 60px;

      & a {
        color: #fff;
      }
    }
  }
  &__copy {
    background: #000000;
    padding: 17px;
  }
}

@media screen and (max-width: 500px) {
  .footer {
    &__main {
      &__socialLinks {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
