.verificationStatus {
  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  &__pending {
    display: flex;
    flex-direction: column;
    align-items: center;

    & h2 {
      font-weight: 700;
      font-size: 24px;
      color: #dc6803;
      margin: 32px 0;
    }

    & p {
      max-width: 500px;
      margin-bottom: 15px;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
      width: 102.86px;
      height: 102.86px;
      background: rgba(54, 184, 110, 0.61);
      border: 4px solid rgba(54, 184, 110, 0.19);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      & img {
        width: 36px;
      }
    }

    & h3 {
      font-weight: 800;
      font-size: 20px;
      color: #58bd7d;
      margin-bottom: 10px;
    }

    & p {
      margin-bottom: 20px;
    }
  }

  &__rejected {
    &__back {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 700;
      margin-bottom: 30px;
      cursor: pointer;
      @include themed() {
        color: t("textAlt");
      }
    }

    & p {
      color: #ed4747;
      background: rgba(238, 154, 154, 0.2);
      border-radius: 16px;
      padding: 20px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    & ul {
      list-style: disc;
      padding-left: 15px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 30px;
    }

    &__doc {
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
        margin-bottom: 30px;
        gap: 10px;

        @media screen and (max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &__left {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        & button {
          color: #eb001b;
          background-color: transparent;
          border: 1px solid #eb001b;
          padding: 7px 15px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
