.paymentCongratModal {
  background-color: $bg-color-alt;
  padding: 30px;
  width: 500px;
  display: flex;
  border-radius: 20px;
  //   grid-template-columns: 1fr;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: $text-color;

  &__closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  &__header {
    background-image: url(../../../image/gliter.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include flex-center-space;
    font-weight: 700;
    font-size: 35px;
    line-height: 64px;
    color: #fff;
    margin-bottom: 20px;
  }

  & p {
    font-weight: 400;
    font-size: 18px;

    text-align: center;

    color: #ffffff;

    & span {
      margin-top: 10px;
      font-weight: 700;
      font-size: 25px;
      color: #58bd7d;
      display: inline-block;
    }
  }
}

@media screen and (max-width: 600px) {
  .paymentCongratModal {
    padding: 30px 20px;
    margin: 0 2.5%;
    width: 95%;
    max-width: 600px;
  }
}
