.authLayout {
  min-height: 100vh;
  width: 100%;
  // background: url(../../../image/abstractBg.png),
  //   url(../../../image/svg/authbg.svg);
  @include themed() {
    background: t("bgAuth");
    background-position: 0 0, 0 0;
    background-repeat: no-repeat;
  }
  //   background-size: cover;
  padding: 0 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: space-between;
  gap: 20px;

  &__main {
    min-width: 250px;
    max-width: 700px;
    @include themed() {
      background-color: t("bgAlt");
    }
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 30px;
  }

  &__header {
    @include flex-center-space;
    width: 100%;
    margin-top: 30px;
    gap: 10px;
    margin-bottom: 70px;
  }
  &__footer {
    @include flex-center;
    flex-direction: column;
    margin-bottom: 20px;

    &__disc,
    &__link {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      @include themed() {
        color: t("textAlt");
      }
      margin: 0;
    }
    &__link {
      color: $primary-color;
    }
  }
}

@media screen and (max-width: 450px) {
  .authLayout {
    justify-content: flex-start;
    &__header {
      margin-bottom: 50px;
      &__logo {
        width: 140px;
      }
      &__btn {
        width: 100px !important;
        height: 35px !important;
      }
    }

    &__main {
      justify-self: center;
      padding: 20px;
      margin-bottom: 20px;
    }

    &__footer {
      display: none;
    }
  }
}
