.invoice {
  background-color: $bg-color-alt;
  padding: 30px;
  width: 500px;
  display: flex;
  border-radius: 20px;
  //   grid-template-columns: 1fr;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: $text-color;
  overflow: auto;

  &__closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  &__header {
    @include flex-center-space;
    font-weight: 700;
    font-size: 35px;
    line-height: 64px;
    color: #fff;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
    margin-bottom: 20px;

    &__item {
      @include flex-center-space;
      gap: 40px;

      &__name {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #fff;
      }

      &__value {
        width: 200px;
        background-color: $bg-color-alt2;
        padding: 15px 20px;
        border-radius: 8px;
        @include flex-center;
        justify-content: flex-end;
      }
    }
  }

  &__summary {
    background-color: $bg-color-alt2;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    &__title {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      margin-bottom: 20px;
      text-align: center;
    }

    &__main {
      width: 350px;
      &__method {
        @include flex-center;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(177, 181, 195, 0.2);

        & img {
          width: 24px;
          margin-right: 10px;
        }
      }

      &__item {
        @include flex-center-space;
        margin-bottom: 10px;
        // color: $text-color-alt;
      }

      &__total {
        padding-top: 10px;
        border-top: 1px solid rgba(177, 181, 195, 0.2);

        color: #fff;
        @include flex-center-space;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .invoice {
    padding: 30px 20px;
    margin: 0 2.5vw;
    width: 95vw;
    max-width: 600px;
    max-height: 95vh;
    &__info {
      &__item {
        gap: 20px;
      }
    }

    &__summary {
      &__main {
        width: 70vw;
      }
    }
  }
}
