.getHelp {
  &__disc {
    font-weight: 400;
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 10px !important;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    width: 269px;
    height: 33px;
    background: linear-gradient(180deg, #eaa613 0%, #efc262 100%);
    border-radius: 8px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__modal {
    background-color: #fff;
    padding: 30px;
    width: 800px;
    max-height: 90vh;
    display: flex;
    border-radius: 20px;
    //   grid-template-columns: 1fr;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: $text-color;
    overflow: auto;

    @media screen and (max-width: 850px) {
      padding: 30px 20px;
      margin: 0 2.5%;
      width: 95%;
      //   max-width: 600px;
    }

    &__closeBtn {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      & h2 {
        background: linear-gradient(
          94.96deg,
          #5158f6 0.8%,
          #822df5 27.15%,
          #f3a761 97.42%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        font-weight: 400;
        font-size: 24px;
        margin-bottom: 10px;
      }

      & h3 {
        font-weight: 700;
        font-size: 24px;
        color: #000;
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;

      & p {
        font-weight: 600;
        color: #000;
        font-size: 17px;
        max-width: 620px;

        & span {
          color: $primary-color;
        }
      }

      & img {
        margin: 10px 0;
        width: 100%;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
}
