.blogItemPage {
  position: relative;
  padding: 65px 7%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__links {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 155px;
    right: 7%;
    z-index: 1;

    & img {
      cursor: pointer;
      position: relative;
      top: -5px;
      left: 3px;
    }
  }

  &__backBtn {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30px;
    left: 7%;
    z-index: 1;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 5px;
      gap: 10px;
      cursor: pointer;

      & span {
        font-size: 18px;
      }
    }
  }

  &__img {
    width: 100%;
    height: 410px;
    object-fit: cover;
    border-radius: 24px;
    margin-bottom: 50px;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    &__main {
      & h5 {
        font-size: 20px;
      }
    }
  }

  &__main {
    max-width: 822px;
    padding: 0 60px;

    & button {
      border: none;
      width: 280px;
      height: 50px;
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      border-radius: 50px;
      cursor: pointer;
    }

    &__subtitle {
      font-weight: 600;
      font-size: 25px;
      line-height: 36px;
    }

    & h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 75px;
      margin-bottom: 25px;
    }

    & h2 {
      font-weight: 700;
      font-size: 24px;
      //   line-height: 40px;
      margin: 20px 0;
      line-height: 32px;
    }

    & p {
      margin: 20px 0;
      font-size: 18px;
      line-height: 32px;
      font-weight: 300;
    }

    & ul {
      list-style: disc;
      padding-left: 20px;
    }
    & li {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .blogItemPage {
    &__links {
      position: absolute;
      bottom: 50px;
      top: auto;
      left: 7%;
      right: auto;
      flex-direction: row;
      gap: 20px;

      & img {
        top: -2px;
        left: 0;
      }
    }
    &__main {
      padding: 0;
      margin-bottom: 60px;

      & h1 {
        font-size: 50px;
        line-height: 50px;
      }
      & h2 {
        font-weight: 700;
        font-size: 30px;
        //   line-height: 40px;
      }

      & p {
        font-size: 17px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blogItemPage {
    &__main {
      & h1 {
        font-size: 40px;
        line-height: 50px;
      }
      & h2 {
        font-weight: 700;
        font-size: 30px;
        //   line-height: 40px;
      }

      & p {
        font-size: 17px;
      }
    }
  }
}
