.notification {
  &__title {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }

  &__main {
    padding-bottom: 25px;
    border-bottom: 1px solid #9ea5bb;
    margin-bottom: 30px;
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &__disc {
        & h3 {
          font-weight: 700;
          font-size: 14px;
        }

        & p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}
