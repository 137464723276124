.session {
  &__title {
    font-weight: 700;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 25px;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;

    & button {
      border: 1px solid rgb(140, 140, 140);
      background-color: transparent;
      padding: 15px 30px;
      @include themed() {
        color: t("textAlt");
      }
      border-radius: 30px;
      cursor: pointer;
    }
  }

  &__main span {
    display: inline-block;
    border: none;
    background-color: transparent;
    @include themed() {
      color: t("bgAlt");
    }
    @include themed() {
      background-color: t("textAlt");
    }
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 10px;
  }

  &__history span {
    display: inline-block;
    border: none;
    background-color: transparent;
    background: #58bd7d;
    color: #fcfcfd;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    width: 64px;
    height: 26px;
    @include flex-center;

    &.no {
      background: #ff6838;
    }
  }

  &__table {
    overflow: auto;
    padding-bottom: 20px;

    &__body {
      display: flex;
      flex-direction: column;
      min-width: 500px;
    }

    &__row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      // background: #202531;
      &:not(:last-child) {
        border-bottom: 1px solid rgb(184, 184, 184);
      }

      &__head {
        font-weight: 700;
        font-size: 14px;
      }

      &__data {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;

        & h4 {
          font-weight: 700;
          @include themed() {
            color: t("textAlt");
          }
        }
      }
    }
  }
}
