.selfieWithId {
  &__title {
    font-weight: 700;
    font-size: 22px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;

    & span {
      color: #58bd7d;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    margin-bottom: 30px;
    cursor: pointer;
    @include themed() {
      color: t("textAlt");
    }
  }

  & h3 {
    font-weight: 800;
    font-size: 16px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }

    &__left {
      height: 100%;

      &__upload {
        height: 100%;
        min-height: 200px;
        border: 1px dashed #979caa;
        border-radius: 8px;
        margin-bottom: 17px;
        overflow: hidden;
        @include themed() {
          background-color: t("bgAlt");
        }
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & p {
          margin-top: 16px;
          font-weight: 600;
          font-size: 14px;
          color: #666dff;
          text-align: center;
          cursor: pointer;

          & span {
            font-size: 12px;

            @include themed() {
              color: t("text");
            }
          }
        }
      }
    }

    &__right {
      font-size: 12px;

      & ul {
        list-style: disc;
        padding-left: 10px;

        & li {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}
