.chart {
  @include themed() {
    background-color: t("bgAlt");
  }
  padding: 20px 40px;
  border-radius: 10px;

  .recharts-tooltip-wrapper {
    outline: none !important;
    &:focus-visible {
      outline: none !important;
    }
  }

  &__header {
    @include flex-center-space;
    margin-bottom: 40px;
    gap: 20px;

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      @include themed() {
        color: t("textAlt");
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 14px;

      &__btn {
        cursor: pointer;
        width: 65px;
        height: 32.58px;
        @include themed() {
          background-color: t("bg");
        }
        // background: $bg-color-alt2;
        border-radius: 9.67091px;
        border: none;
        @include themed() {
          color: t("text");
        }
        font-weight: 400;
        font-size: 12.3084px;

        &.active {
          background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
          color: #fff;
        }
      }
    }
  }

  &__customTooltip {
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    @include themed() {
      background-color: t("bgAlt");
    }
    // background-color: $bg-color-alt2;
    box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 9px 4px rgba(0, 0, 0, 0.08);
  }
}

@media screen and (max-width: 700px) {
  .chart {
    &__header {
      flex-direction: column;
    }
  }
}
