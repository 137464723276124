.hero {
  overflow: hidden;
  position: relative;
  &__video {
    height: 1000px;
    width: 125%;
    object-fit: cover;
    z-index: 1;
  }

  &__main {
    position: absolute;
    left: 7%;
    padding-right: 7%;
    top: 100px;
    max-width: 50%;
    z-index: 2;

    & h2 {
      font-weight: 700;
      font-size: 40px;
      background: linear-gradient(
        91.21deg,
        #b88717 19.54%,
        #ca9926 35.84%,
        #e1af3a 55.77%,
        #f5d281 66.18%,
        #ffd166 75.24%,
        #c48e0e 106.48%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      //   text-fill-color: transparent;
      text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
    }

    & h1 {
      font-weight: 700;
      font-size: 96px;
      line-height: 85px;
      color: #09090b;
      max-width: 633px;
    }

    & p {
      font-weight: 300;
      font-size: 24px;
      max-width: 376px;
    }

    &__btn {
      cursor: pointer;
      width: 213px;
      height: 56px;
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      //   position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-top: 43px;
    }
  }
  &__curve {
    position: absolute;
    bottom: -70px;
    width: 100%;
    z-index: 2;
  }
}

@media screen and (max-width: 1142px) {
  .hero {
    &__main {
      & h1 {
        font-size: 70px;
        line-height: 55px;
      }
    }
  }
}
@media screen and (max-width: 1142px) {
  .hero {
    &__curve {
      height: 240px;
    }
  }
}

@media screen and (max-width: 930px) {
  .hero {
    &__video {
      width: 135%;
    }
  }
}

@media screen and (max-width: 750px) {
  .hero {
    &__video {
      height: 1000px;
      width: 165%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 600px) {
  .hero {
    &__main {
      max-width: 70%;

      & h2 {
        font-size: 30px;
      }

      & h1 {
        font-size: 50px;
        line-height: 55px;
      }

      & p {
        font-size: 24px;
      }
    }
    &__video {
      height: 1000px;
      width: 175%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 500px) {
  .hero {
    &__main {
      & p {
        font-size: 20px;
        max-width: 80%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .hero {
    &__video {
      height: 1000px;
      width: 185%;
      object-fit: cover;
    }
    &__main {
      & h2 {
        font-size: 24px;
      }

      & h1 {
        font-size: 40px;
        line-height: 55px;
      }

      & p {
        font-size: 20px;
      }
    }
  }
}
