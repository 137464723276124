.subscribeChannel {
  margin-top: 25px;
  @include themed() {
    background-color: t("bgAlt");
  }
  padding: 25px 0;

  &__header {
    @include flex-center-space;
    margin-bottom: 25px;
    padding: 0 20px;
    gap: 20px;

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      @include themed() {
        color: t("textAlt");
      }
    }
  }

  &__platformBtns {
    padding: 0 40px;
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    row-gap: 15px;
    justify-content: center;
    margin-bottom: 30px;

    &__item {
      width: 220px;
      min-height: 39px;
      @include themed() {
        background: t("bg");
      }
      // background: #202531;
      border-radius: 5px;
      border: none;
      color: #787a8485;
      cursor: pointer;
      padding: 10px;
      text-transform: capitalize;

      &.active {
        @include themed() {
          color: t("textAlt");
        }
      }
    }
  }

  &__table {
    overflow: auto;
    padding-bottom: 20px;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 500px;
    }

    &__row {
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 55px;
      @include themed() {
        background: t("bgAlt2");
      }
      // background: #202531;
      border: 0.838636px solid rgba(255, 255, 255, 0.05);

      &__head {
        font-weight: 700;
        font-size: 13.4182px;
        line-height: 20px;
        text-transform: uppercase;
        @include themed() {
          color: t("textAlt");
        }
      }

      &__data {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &__icon {
          display: flex;
          align-items: center;
          gap: 8px;

          & img {
            width: 20px;
            height: 20px;
          }
        }

        &__btn {
          width: 82px;
          height: 31px;
          background: rgba(47, 180, 255, 0.2);
          border-radius: 5px;
          border: none;
          color: #2fb4ff;
          cursor: pointer;

          &.subscribed {
            background: rgba(44, 207, 84, 0.2);
            color: #2ccf54;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .subscribeChannel {
    &__header {
      @include flex-center-space;
      flex-direction: column;

      &__title {
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
