.topbar {
  z-index: 3;
  @include flex-center-space;
  // @include themed() {
  //   padding: t("topBarPadding");
  // }
  padding-right: 20px;
  position: fixed;
  left: 0;
  top: 0;
  height: $topbar-height;
  width: 100%;
  @include themed() {
    background-color: t("bgAlt");
  }

  &__left {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 100%;
    @include themed() {
      background: t("topBarlogoBg");
    }
  }

  &__right {
    display: flex;
    align-items: center;
    // gap: 30px;

    &__logoutBtn {
      margin-left: 20px;
      background-color: transparent;
      border: 1px solid rgb(176, 176, 176);
      padding: 7px 20px;
      @include themed() {
        color: t("text");
      }
      border-radius: 20px;
      cursor: pointer;
    }

    &__burgerIcon {
      cursor: pointer;
      display: none;
    }

    &__themeIcon {
      margin-right: 10px;
      cursor: pointer;
    }

    &__profile {
      @include themed() {
        color: t("text");
      }

      display: flex;
      align-items: center;

      &__icon {
        @include flex-center;
        width: 34px;
        height: 34px;
        border: 1px solid rgb(169, 166, 166);
        border-radius: 50%;
        margin-right: 10px;
        overflow: hidden;

        & img {
          width: 34px;
        }
      }
    }

    &__btn {
      width: 157px;
      height: 40px;

      border: 1px solid #ffffff;
      border-radius: 90px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1000px) {
  .topbar {
    &__right {
      &__burgerIcon {
        display: inline-block;
      }
      // &__themeIcon {
      //   display: none;
      // }
      &__profile {
        display: none;
      }
      &__logoutBtn {
        display: none;
      }
    }
  }
}
// @media screen and (max-width: 600px) {
//   .topbar {
//     &__right {
//       &__profile {
//         display: none;
//       }
//     }
//   }
// }
