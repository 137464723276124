.eligibilityModal {
  background: #171b23;
  border-radius: 10px;
  padding: 30px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #e2e4ed;
    margin-bottom: 15px;
  }

  &__disc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #e2e4ed;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .eligibilityModal {
    margin: 0 2.5%;
    width: 95%;
    max-width: 600px;
  }
}

@media screen and (max-width: 360px) {
  .eligibilityModal {
    padding: 20px;
  }
}
