.drawerL {
  position: fixed;
  width: 100%;
  top: 70px;
  //   background: $bg-linear-gradient;
  background-color: #0e0f1f;
  left: 120vw;
  padding: 2rem 10%;
  z-index: 3;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
  transition: 0.3s;

  &.open {
    left: 0;
  }

  &__links {
    margin: 0;
    @include flex-center;
    flex-direction: column;
    gap: 3rem;

    & a {
      color: #fff;
    }
  }
}
