.biometricRegisterModal {
  background: #171b23;
  border-radius: 10px;
  padding: 30px;
  //   max-width: 520px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  //   margin: 0 5%;
  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  & p {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__biometric {
    margin-top: 30px;
    box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.35),
      -6px -6px 16px -7px rgba(52, 47, 47, 0.55);
    border-radius: 12px;
    padding: 30px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 30px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  }
}

@media screen and (max-width: 700px) {
  .biometricRegisterModal {
    margin: 0 2.5vw;
    width: 95vw;
    // max-width: 600px;
  }
}

@media screen and (max-width: 360px) {
  .biometricRegisterModal {
    padding: 20px;
  }
}
