.drawer {
  display: flex;
  position: fixed;
  top: $topbar-height;
  right: 120vw;
  width: 100%;
  height: calc(100vh - $topbar-height);
  @include themed() {
    background-color: t("bg");
  }
  z-index: $zIndex;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;
  justify-content: flex-start;

  @media screen and (min-width: 1000px) {
    display: none;
  }

  &.open {
    right: 0;
  }
}
