.programsInfo {
  margin-top: 25px;
  border-radius: 10px;
  @include themed() {
    background-color: t("bgAlt");
  }
  padding: 20px;

  &__header {
    @include flex-center-space;
    gap: 20px;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      @include themed() {
        color: t("textAlt");
      }
    }
  }

  & ol {
    padding-left: 20px;

    & li {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      @include themed() {
        color: t("textAlt");
      }
    }
  }

  &__mission {
    &__title {
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    &__disc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 600px) {
  .programsInfo {
    &__header {
      flex-direction: column;
      margin-bottom: 30px;
      &__title {
        text-align: center;
        font-size: 18px;
      }
    }

    & ol {
      & li {
        font-size: 15px;
      }
    }
  }
}
