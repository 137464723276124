.landingLayout {
  font-family: "Kanit", sans-serif;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
  padding-top: 70px;

  & button {
    color: black;
  }
}
