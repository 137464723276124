.cashForCharity {
  &__programs {
    padding: 20px;
    margin-top: 30px;
    @include themed() {
      background-color: t("bgAlt");
    }
    border-radius: 8px;

    &__title {
      font-weight: 700;
      font-size: 20px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 8px;
    }

    &__main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        &__title {
          background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          margin-bottom: 8px;
        }

        &__btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .cashForCharity {
    &__programs {
      &__main {
        grid-template-columns: 1fr;
      }
    }
  }
}
