.introducer {
  padding: 0 60px;

  &__header {
    text-align: center;
    margin-bottom: 32px;

    & h1 {
      font-weight: 700;
      font-size: 36px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 16px;
    }
  }

  &__disc {
    margin-bottom: 16px;
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 37px;

    &__item {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }

  &__form {
    @include themed() {
      background-color: t("bgAlt");
    }
    border-radius: 20px;
    padding: 40px 10%;

    &__part1 {
      &__btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
      }

      &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 35px;
        @include themed() {
          color: t("textAlt");
        }
      }

      &__userinfo {
        margin-bottom: 32px;
        & h4 {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 16px;
        }
        &__main {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          column-gap: 30px;
          row-gap: 14px;
        }
      }

      &__upload {
        & h4 {
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 16px;
        }

        &__main {
          display: flex;
          flex-direction: column;
          gap: 24px;

          &__item {
            display: flex;
            flex-wrap: wrap;
            gap: 5%;
            align-items: center;

            & p {
              width: 220px;
            }

            &__click {
              cursor: pointer;
              width: 180px;
              height: 32px;
              @include themed() {
                background-color: t("bgAlt2");
              }
              border-radius: 2px;
              display: flex;
              align-items: center;
              padding: 10px 18px;
              gap: 10px;
              font-weight: 400;
              font-size: 14px;
            }

            &__name {
              @include themed() {
                color: #1890ff;
                font-size: 14px;
                height: 32px;
                display: flex;
                align-items: center;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }

    &__part2 {
      &__btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
        gap: 20px;
        flex-wrap: wrap;
      }

      &__title {
        @include themed() {
          color: t("textAlt");
        }
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 35px;
      }

      &__subTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 24px;
      }

      &__employer {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        column-gap: 30px;
      }

      &__date {
        display: flex;
        gap: 30px;
        margin-bottom: 24px;
      }

      &__employment {
        margin-bottom: 32px;

        &__title {
          margin-bottom: 19px;
        }

        &__main {
          display: flex;
          flex-wrap: wrap;
          gap: 25px;
        }
      }

      &__inputDisc {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
    }

    &__part3 {
      &__title {
        @include themed() {
          color: t("textAlt");
        }
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 35px;
      }

      &__subTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 24px;
      }

      &__disc {
        max-width: 761px;
        margin-bottom: 40px;
      }

      &__guide {
        display: flex;
        gap: 20px;
        margin-bottom: 40px;

        &__right {
          & h5 {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 10px;
          }

          & button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 1px solid #58bd7d;
            height: 40px;
            width: 180px;
            gap: 10px;
            border-radius: 5px;
            color: inherit;
          }
        }
      }

      &__input {
        max-width: 550px;
      }

      &__btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 70px;
        gap: 20px;
        flex-wrap: wrap;
      }

      &__back {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        margin-bottom: 30px;
      }

      &__details {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 700px) {
          flex-direction: column;
        }

        &__right {
          width: calc(100% - 86px);
          @media screen and (max-width: 700px) {
            width: 100%;
          }

          & h5 {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 16px;
          }
          & ol {
            padding: 0;
            padding-left: 15px;
          }
        }
      }

      &__date {
        margin-top: 20px;

        & h5 {
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 20px;
        }

        &__main {
          border: 1px solid rgba(119, 126, 144, 0.3);
          border-radius: 8px;
          padding: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
          max-width: 600px;

          @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
          }

          &__time {
            & label {
              font-size: 14px;
              margin-bottom: 8px;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .introducer {
    padding: 0px;
  }
}

@media screen and (max-width: 600px) {
  .introducer {
    &__header {
      & h1 {
        font-size: 25px;
      }
    }

    &__form {
      padding: 40px 6%;
      &__part2 {
        &__employer {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .introducer {
    &__form {
      &__part1 {
        &__upload {
          &__main {
            &__item {
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
