.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 7%;
  align-items: center;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f7f7f7;
  z-index: 3;

  &.white {
    background-color: #fff;
  }

  &__logo {
    & img {
      width: 160px;
    }
  }

  &__main {
    &__links {
      display: flex;
      gap: 20px;

      & a {
        color: #000;
        transition: 0.3s;
      }

      &__item {
        &.active {
          color: #c48e0e;
          font-weight: 700;
        }
      }
    }

    &__icon {
      display: none;
      width: 20px;
      height: 15px;
      position: relative;
      // margin: 20px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      // display: none;
      // color: var(--primary-color);
      // cursor: pointer;

      & span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #000;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      & span:nth-child(1) {
        top: 0px;
      }

      & span:nth-child(2) {
        top: 5px;
      }

      & span:nth-child(3) {
        top: 10px;
      }

      &.open span:nth-child(1) {
        top: 5px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &.open span:nth-child(2) {
        opacity: 0;
        left: -30px;
      }

      &.open span:nth-child(3) {
        top: 5px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;

    &__alt {
      width: 88px;
      height: 38px;
      background: #f7f7f7;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      position: relative;
      border: none;

      &::before {
        cursor: pointer;
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 50px;
        padding: 1px;
        background: linear-gradient(
          90deg,
          #b88717 -5.35%,
          #ffd166 21.73%,
          #f5d281 42.92%,
          #c48e0e 107.68%
        );
        mask: linear-gradient(#f7f7f7 0 0) content-box,
          linear-gradient(#f7f7f7 0 0);
        -webkit-mask: linear-gradient(#f7f7f7 0 0) content-box,
          linear-gradient(#f7f7f7 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }

    &__main {
      cursor: pointer;

      width: 88px;
      height: 38px;
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 50px;
      position: relative;
      border: none;
    }
  }
}

@media screen and (max-width: 950px) {
  .navbar {
    //   justify-content: flex-end;
    &__buttons {
      display: none;
    }
    &__main {
      &__links {
        display: none;
      }
      &__icon {
        display: block;
      }
    }
  }
}
