.wallet {
  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }

    &__img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: #5960ee27;
      @include flex-center;
    }
  }

  &__main {
    margin-bottom: 24px;
    padding: 20px;
    @include themed() {
      background-color: t("bgAlt");
      border-radius: 10px;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 8px;
    }

    &__disc {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 24px;
    }

    &__coinbase,
    &__solana {
      &__title {
        font-weight: 800;
        font-size: 16px;
        @include themed() {
          color: t("textAlt");
        }
        margin-bottom: 22px;
      }

      &__wallets {
        display: flex;
        row-gap: 40px;
        column-gap: 10px;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 40px;

        &__item {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 180px;
          padding: 7px;
          cursor: pointer;

          &.active {
            background: $primary-gradient-color;
            border-radius: 8px;
            color: white;
          }

          & img {
            border-radius: 6px;
            width: 36px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          }
        }
      }
    }

    &__address {
      display: flex;
      gap: 20px;
      align-items: flex-start;

      &__inputCon {
        width: 300px;
      }
    }
  }

  &__faq {
    margin-bottom: 24px;
    padding: 20px;
    @include themed() {
      background-color: t("bgAlt");
      border-radius: 10px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;

    &__item {
      &__main {
        display: flex;
        margin-bottom: 20px;
        gap: 16px;

        &__disc {
          & span {
            font-weight: 400;
            font-size: 14px;
            display: inline-block;
          }

          & p {
            font-weight: 700;
            font-size: 14px;
            @include themed() {
              color: t("textAlt");
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .wallet {
    &__main {
      &__address {
        flex-direction: column;
        align-items: flex-start;
        &__inputCon {
          width: 100%;
        }
      }
    }
    &__faq {
      grid-template-columns: 1fr;
      row-gap: 30px;
    }
  }
}
