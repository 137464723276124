.cfc {
  background-color: #0e0f1f;
  color: #fff;
  padding: 80px 7%;
  font-family: "DM Sans", sans-serif;
  background-image: url(../../../../image/svg/wave.svg);
  background-position: center;
  background-repeat: no-repeat;

  & h2,
  & h3 {
    background: linear-gradient(
      90deg,
      #b88717 -5.35%,
      #ffd166 21.73%,
      #f5d281 42.92%,
      #c48e0e 107.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  & h2 {
    font-weight: 900;
    font-size: 48px;
    font-family: "Kanit", sans-serif;
  }
  & h3 {
    font-weight: 700;
    font-size: 45px;
    font-family: "DM Sans", sans-serif;
  }
  & h4 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 16px;
    max-width: 950px;
  }

  & p {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 20px;
    max-width: 950px;
    max-width: 750px;
  }

  & button {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 45px;
    border-radius: 50px;
    position: relative;
    border: none;
    background: linear-gradient(
      90deg,
      #b88717 -5.35%,
      #ffd166 21.73%,
      #f5d281 42.92%,
      #c48e0e 107.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    &::before {
      cursor: pointer;
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1px;
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      mask: linear-gradient(#191a38 0 0) content-box,
        linear-gradient(#191a38 0 0);
      -webkit-mask: linear-gradient(#191a38 0 0) content-box,
        linear-gradient(#191a38 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
