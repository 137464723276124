.helpCentre {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    width: 150px;
    margin-bottom: 25px;
  }

  &__main {
    border: 1px solid #262626;
    padding: 30px;
    border-radius: 16px;

    &__title {
      font-weight: 800;
      font-size: 35px;
      @include themed() {
        color: t("textAlt");
      }
      text-align: center;
      margin-bottom: 20px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 50px;

      &__item {
        border: 0.899761px solid rgba(157, 165, 178, 0.5);
        border-radius: 8.99761px;
        padding: 10px 20px;
        cursor: pointer;
      }
    }
  }
}
