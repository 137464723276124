@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
