.applicationResponse {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  padding: 30px;
  border-radius: 9.30023px;
  text-align: center;
  background-color: $bg-color-alt;
  min-height: 400px;

  & img {
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
    color: #fff;
  }
  &__subTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  &__disc {
    font-size: 14px;

    & a {
      background: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  &__btn {
    margin: 30px 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 750px) {
  .applicationResponse {
    width: 95%;
    margin: 0 2.5%;
  }
}
