.recovery {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-weight: 800;
      font-size: 32px;
      //   line-height: 20px;
      text-align: center;
      @include themed() {
        color: t("textAlt");
      }
      margin: 0;
      margin-bottom: 20px;
    }

    &__disc {
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
    }

    &__input {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      //   align-items: center;

      &__icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 66px;
        height: 60px;
        @include flex-center;
        background: rgba(91, 100, 116, 0.06);
        border-radius: 8px 0px 0px 8px;
      }
    }

    &__line {
      margin: 40px 0;
      margin-bottom: 24px;
      width: 100%;
      height: 1px;
      background: #4d4d4d;
    }

    &__bottomDisc {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-weight: 800;
      font-size: 32px;
      //   line-height: 20px;
      text-align: center;
      @include themed() {
        color: t("textAlt");
      }
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
