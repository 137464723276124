.kyc {
  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    color: $primary-color;

    & span {
      position: relative;
      bottom: 1px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 45px;
    gap: 20px;

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 7px 15px;
      transition: 0.3s;

      &.active {
        @include themed() {
          background-color: t("bgAlt2");
        }
        box-shadow: 0px 4px 16px -8px rgba(15, 15, 15, 0.1);
        border-radius: 48px;
      }

      &__num {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        @include themed() {
          border: 2px solid t("textAlt");
        }
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.complete {
          border: none;
          background-color: #58bd7d;
          color: white;
        }
      }

      & span {
        font-size: 12px;
        &.complete {
          @include themed() {
            color: t("textAlt");
          }
        }
      }
    }
  }

  &__main {
    @include themed() {
      background-color: t("bgAlt2");
    }
    padding: 20px;
    border-radius: 16px;
    min-height: 300px;
  }
}
