.wealth {
  padding: 80px 7%;
  color: #fff;
  background-color: #0e0f1f;
  background-image: url(../../../../image/CRYPTOS.png);
  background-repeat: no-repeat;
  background-position: center;
  font-weight: 600;
  font-size: 20px;

  & p {
    max-width: 60%;
    & b {
      font-size: 30px;
    }
  }

  & button {
    cursor: pointer;
    margin-top: 50px;
    width: 250px;
    height: 54px;
    border: none;
    font-size: 16px;
    background: linear-gradient(
      90deg,
      #b88717 -5.35%,
      #ffd166 21.73%,
      #f5d281 42.92%,
      #c48e0e 107.68%
    );
    border-radius: 50px;
  }
}

@media screen and (max-width: 600px) {
  .wealth {
    background-image: none;
    & p {
      max-width: 100%;
      & b {
        font-size: 30px;
      }
    }
  }
}
