.mission {
  overflow: hidden;
  position: relative;
  color: #fff;
  height: 680px;
  background-color: #000;

  &__video {
    height: 600px;
    width: 100%;
    object-fit: cover;
  }

  &__main {
    position: absolute;
    top: 100px;
    left: 7%;
    max-width: 500px;

    & h2 {
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-weight: 700;
      font-size: 48px;
    }

    & p {
      font-weight: 300;
      font-size: 32px;
    }
  }
}
