.blog {
  padding: 80px 7%;
  padding-right: 0;
  background: #f1f1f4;
  border-top: 1px solid rgb(77, 77, 77);

  &__header {
    padding-right: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
    flex-wrap: wrap;
    gap: 20px;

    & h2 {
      background: linear-gradient(227.07deg, #4e0062 0.43%, #220076 99.56%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 700;
      font-size: 48px;
    }

    & p {
      font-weight: 400;
      font-size: 20px;
    }

    & button {
      width: 159px;
      height: 57px;
      background: #f1f1f4;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 75px;
      border: 1px solid #220076;
      color: #220076;
      cursor: pointer;
    }
  }

  &__main {
    &__item {
      width: 430px;
      background-color: #fff;
      box-shadow: 0px 6px 45px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      padding: 16px;

      &__img {
        height: 290px;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
      }

      & h3 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 16px;
      }

      & p {
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 25px;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left,
        &__right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blog {
    &__main {
      &__item {
        width: 350px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .blog {
    &__main {
      &__item {
        width: 95%;
      }
    }
  }
}
