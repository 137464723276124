.whatWeOffer {
  background-color: #0e0f1f;
  background-image: url(../../../../image/svg/CTA.svg);
  //   background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 300px;
  color: #fff;
  padding: 70px 7%;

  &__main {
    background: rgba(35, 61, 151, 0.7);
    // backdrop-filter: blur(10px);
    padding: 50px;
    border-radius: 16px;
    text-align: center;
    margin: 0 10%;

    & h2 {
      background: linear-gradient(
        90deg,
        #b88717 -5.35%,
        #ffd166 21.73%,
        #f5d281 42.92%,
        #c48e0e 107.68%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      //   text-fill-color: transparent;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 25px;
    }

    & p {
      font-weight: 400;
      font-size: 18px;
      font-family: "DM Sans", sans-serif;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 600px) {
  .whatWeOffer {
    &__main {
      margin: 0;
      padding: 50px 20px;

      & h2 {
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 25px;
      }

      & p {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
}
