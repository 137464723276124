.faqPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Epilogue", sans-serif;

  &__header {
    padding: 0 7%;

    background-image: url(../../../../assets/image/svg/globe.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 336px;
    @include flex-center;
    flex-direction: column;

    & h1 {
      font-weight: 700;
      font-size: 48px;
      margin-bottom: 20px;
      text-align: center;
    }

    &__input {
      position: relative;
      width: 60%;
      display: flex;
      justify-content: center;

      & input {
        background: #ffffff;
        border: 2px solid #c1c1c1;
        border-radius: 50px;
        height: 77.17px;
        width: 100%;
        max-width: 783px;
        padding: 30px;
        padding-left: 80px;
        font-size: 24px;
        color: #000;

        &:hover {
          border: 2px solid 0f1f;
        }

        &:hover {
          border: 2px solid 0f1f;
        }
      }

      & img {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
      }
    }
  }

  &__group {
    padding: 100px 7%;
    padding-top: 40px;
    display: flex;
    justify-content: center;

    &__mainList {
      column-count: 2;
      column-gap: 24px;

      &__item {
        color: #170f49;

        break-inside: avoid;
        background: #ffffff;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.06);
        border-radius: 16px;
        padding: 24px;
        margin-bottom: 24px;

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          //   gap: 50px;

          & h3 {
            font-weight: 600;
            font-size: 22px;
            width: 80%;
            // line-height: 36px;
          }

          &__icon {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              #3b0088;
            border-radius: 8px;
            height: 30px;
            width: 30px;
            color: #ffffff;
            @include flex-center;
            font-size: 25px;
          }
        }

        & p {
          font-weight: 400;
          font-size: 17px;
        }
      }
    }

    &__list {
      max-width: 1022px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;

      &__item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & img {
          transition: 0.3s;
          &:hover {
            transform: scale(1.2);
          }
        }

        & h2 {
          font-weight: 400;
          font-size: 20px;
          margin-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 710px) {
  .faqPage {
    &__group {
      &__mainList {
        column-count: 1;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .faqPage {
    &__header {
      &__input {
        position: relative;
        width: 100%;

        & input {
          height: 40px;
        }
      }
      & h1 {
        font-size: 35px;
      }
    }

    &__group {
      &__list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .faqPage {
    &__group {
      &__list {
        grid-template-columns: 1fr;
      }
    }
  }
}
