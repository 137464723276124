.verify {
  &__title {
    font-weight: 700;
    font-size: 22px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;

    & span {
      color: #58bd7d;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__dis {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;

    &__left {
      max-width: 380px;
      h4 {
        font-weight: 700;
        font-size: 16px;
        @include themed() {
          color: t("textAlt");
        }
        margin-bottom: 24px;
      }

      & p {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }

  & h3 {
    font-weight: 700;
    font-size: 18px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }

    &__left,
    &__right {
      & h4 {
        font-weight: 800;
        font-size: 12px;
      }

      &__upload {
        height: 152px;
        border: 1px dashed #979caa;
        border-radius: 8px;
        margin-bottom: 17px;
        overflow: hidden;
        @include themed() {
          background-color: t("bgAlt");
        }
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & p {
          margin-top: 16px;
          font-weight: 600;
          font-size: 14px;
          color: #666dff;
          text-align: center;
          cursor: pointer;

          & span {
            font-size: 12px;

            @include themed() {
              color: t("text");
            }
          }
        }
      }

      &__disc {
        font-weight: 400;
        font-size: 12px;
        padding-bottom: 8px;
      }
    }

    &__right {
      &__upload {
        height: 213px;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
