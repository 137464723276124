.profileComplete {
  padding: 20px;
  @include themed() {
    background-color: t("bgAlt");
  }
  border-radius: 12px;
  margin-bottom: 24px;
  display: flex;
  //   align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  &__left {
    max-width: 605px;

    & h3 {
      @include themed() {
        color: t("textAlt");
      }
      font-weight: 800;
      font-size: 18px;
      margin-bottom: 12px;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
  &__right {
    width: 150px;
  }
}
