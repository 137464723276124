.exchange {
  padding: 30px;
  @include themed() {
    background-color: t("bgAlt");
  }

  &__header {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 32px;

    & h1 {
      width: calc(100% - 90px);

      font-weight: 700;
      font-size: 40px;
      @include themed() {
        color: t("textAlt");
      }
    }

    &__icon {
      width: 90px;
      height: 90px;
      background: #5960ee28;
      border-radius: 50%;
      @include flex-center;
    }
  }

  & h2,
  h3 {
    @include themed() {
      color: t("textAlt");
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
  }

  &__disc {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 24px;
    line-height: 25px;
  }

  & h3 {
    font-weight: 700;
    font-size: 16px;
  }

  & ul {
    margin: 0 20px;
    margin-bottom: 20px;

    & li {
      line-height: 25px;
      list-style: circle;
    }
  }

  &__btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .exchange {
    &__header {
      & h1 {
        font-size: 25px;
      }
    }
  }
}
