.reactivateModal {
  background: #171b23;
  border-radius: 10px;
  padding: 30px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__circle {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #e2e4ed;
    margin-bottom: 15px;
  }

  &__disc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #e2e4ed;
    margin-bottom: 30px;
  }

  &__wallet {
    width: 100%;
    border: 1px solid #2a2a39;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-bottom: 40px;

    &__item {
      @include flex-center-space;
      padding: 10px 0;
      border-bottom: 1px solid #2a2a39;

      & span {
        display: flex;
        align-items: center;
        color: #e2e4ed;
        gap: 10px;
      }
    }
  }

  // &__btnCon {
  //   display: flex;
  //   gap: 45px;
  //   &__left {
  //     background: #d75d4f;
  //     color: white;
  //   }
  //   &__left,
  //   &__right {
  //     border: none;
  //     width: 126px;
  //     height: 48px;
  //     border-radius: 5px;
  //     cursor: pointer;
  //   }
  // }
}

@media screen and (max-width: 600px) {
  .reactivateModal {
    margin: 0 2.5%;
    width: 95%;
    max-width: 600px;
  }
}

@media screen and (max-width: 360px) {
  .reactivateModal {
    padding: 20px;
    &__btnCon {
      flex-direction: column;
      gap: 20px;
    }
  }
}
