$primary-color: #666dff;
$primary-color-alt: #5057e4;
$secondary-color: #eaa613;
$primary-gradient-color: linear-gradient(180deg, #666dff 0%, #5057e4 100%);
$secondary-gradient-color: linear-gradient(180deg, #eaa613 0%, #efc262 100%);
$bg-color: #111114;
$bg-color-alt: #171b23;
$bg-color-alt2: #202531;
$text-color: #b1b5c3;
$text-color-alt: #4d4d4d;
$gray: #aeaeae;
$light-gray: #e2e4ed;
$error: #a92424;
$success: #58bd7d;
$zIndex: 2;

$topbar-height: 80px;
$sidebar-width: 260px;
