.refer {
  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;
  }
  &__modal {
    @include themed() {
      background-color: t("bg");
    }
    // background: #171b23;
    border-radius: 10px;
    padding: 30px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__circle {
      width: 80px;
      height: 80px;
      background: $secondary-gradient-color;
      border-radius: 50%;
      margin-bottom: 15px;
    }

    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 15px;
    }

    &__disc {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #e2e4ed;
      margin-bottom: 30px;
    }

    &__btnCon {
      display: flex;
      gap: 45px;
      &__left {
        background: #d75d4f;
        color: white;
      }
      &__left,
      &__right {
        border: none;
        width: 126px;
        height: 48px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 25px;

    &__header {
      min-height: 100px;
      @include flex-center-space;
      gap: 20px;
      @include themed() {
        background-color: t("bgAlt");
      }
      padding: 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      & h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        @include themed() {
          color: t("textAlt");
        }
        & span {
          background: $primary-gradient-color;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      & p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 5px;
      }
    }

    &__referral {
      min-height: 100px;
      //   @include flex-center-space;
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      gap: 20px;
      @include themed() {
        background-color: t("bgAlt");
      }
      padding: 20px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      &__link,
      &__code {
        & h4 {
          //   width: 400px;
          font-weight: 800;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          @include themed() {
            color: t("textAlt");
          }
          margin-bottom: 12px;

          & span {
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #777e90;
          }
        }

        & p {
          @include flex-center;
          justify-content: flex-start;
          height: 48px;
          @include themed() {
            background-color: t("bg");
          }
          // background: #2a2c31;
          border-radius: 12px;
          padding: 0 15px;
        }
      }

      &__code {
        // margin-left: 60px;
        position: relative;

        &__icon {
          @include flex-center;
          position: absolute;
          right: 0;
          top: 24px;
          width: 48px;
          height: 48px;
          background: rgba(69, 69, 69, 0.584);

          // background: $bg-color;
          border-radius: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .refer {
    &__action {
      &__referral {
        grid-template-columns: 1fr;
        // justify-items: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .refer {
    &__action {
      &__header {
        text-align: center;
        flex-direction: column;
      }
    }
  }
}
