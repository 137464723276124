.oneStepId {
  &__title {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 16px;
    @include themed() {
      color: t("textAlt");
    }
  }

  &__disc {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;

    & span {
      @include themed() {
        color: t("textAlt");
      }
    }
  }

  &__recovery {
    &__title {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 8px;
      @include themed() {
        color: t("textAlt");
      }
    }

    &__disc {
      margin-bottom: 25px;
    }

    &__main {
      &__item {
        margin-bottom: 30px;
        &:not(:last-child) {
          border-bottom: 1px solid rgb(147, 147, 147);
        }
        &__header {
          font-weight: 700;
          font-size: 16px;
          @include themed() {
            color: t("textAlt");
          }
          margin-bottom: 25px;
        }

        &__disc {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 24px;
        }

        &__action {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 25px;
          gap: 10px;

          &__input {
            & label {
              display: block;
              margin-bottom: 10px;
              font-weight: 800;
              font-size: 12px;
              text-transform: uppercase;
            }

            & input {
              background-color: transparent;
              width: 250px;
              border: 1px solid rgb(128, 128, 128);
              padding: 0 20px;
              height: 40px;
              border-radius: 10px;
              transition: 0.3s;
              outline: none;

              &::placeholder {
                color: #777e91;
              }
              &:hover {
                outline: none;
                border: 1px solid $primary-color;
              }
              &:focus {
                outline: none;
                border: 1px solid $primary-color;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .oneStepId {
    &__recovery {
      &__main {
        &__item {
          &__action {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
