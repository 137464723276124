.referral {
  &__title {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 30px;
  }

  &__info {
    @include themed() {
      background-color: t("bgAlt");
    }
    border-radius: 17.5835px;
    padding: 20px;

    &__title {
      font-weight: 400;
      font-size: 14.0668px;
      line-height: 19px;
      @include themed() {
        color: t("textAlt");
      }
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &__main {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      //   justify-items: center;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70px;
        gap: 13px;
        margin: 15px 0;
        padding: 5px;

        &:not(:last-child) {
          @include themed() {
            border-right: 1px solid t("referalIconBg");
          }
          border-right: 1px solid #29292b;
        }

        &__img {
          @include flex-center;
          width: 45px;
          height: 45px;
          @include themed() {
            background-color: t("referalIconBg");
          }
          // background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
        }

        &__data {
          & h3 {
            font-weight: 800;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.251186px;
            @include themed() {
              color: t("textAlt");
            }
          }

          & p {
            font-weight: 400;
            font-size: 11.722px;
            line-height: 16px;
            letter-spacing: 0.251186px;
            color: #5e636d;
          }
        }
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    &__header {
      min-height: 100px;
      @include flex-center-space;
      @include themed() {
        background-color: t("bgAlt2");
      }
      // background: #202531;
      padding: 35px 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      & h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        @include themed() {
          color: t("textAlt");
        }
        background: $primary-gradient-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      & p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 5px;
      }
    }

    &__referral {
      min-height: 100px;
      //   @include flex-center-space;
      display: grid;
      grid-template-columns: 3fr 2fr 1fr;
      gap: 20px;
      @include themed() {
        background-color: t("bgAlt");
      }
      padding: 50px 20px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      &__link,
      &__code {
        & h4 {
          //   width: 400px;
          font-weight: 800;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          @include themed() {
            color: t("textAlt");
          }
          // color: #fcfcfd;
          margin-bottom: 12px;

          & span {
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #777e90;
          }
        }

        & p {
          @include flex-center;
          justify-content: flex-start;
          height: 48px;
          @include themed() {
            background-color: t("bg");
          }
          // background: #2a2c31;
          border-radius: 12px;
          padding: 0 15px;
        }
      }

      &__code {
        position: relative;

        &__icon {
          @include flex-center;
          position: absolute;
          right: 0;
          top: 24px;
          width: 48px;
          height: 48px;

          background: rgba(69, 69, 69, 0.584);
          border-radius: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .referral {
    &__info {
      &__main {
        &__item {
          flex-direction: column;
        }
      }
    }
    &__action {
      &__referral {
        grid-template-columns: 1fr;
        // justify-items: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .referral {
    &__info {
      &__main {
        grid-template-columns: repeat(2, 1fr);
        &__item {
          &:nth-child(2) {
            border: none;
          }
        }
      }
    }
    &__action {
      &__header {
        text-align: center;
        flex-direction: column;
      }
    }
  }
}
