.profile {
  &__title {
    margin-bottom: 25px;
    font-weight: 700;
    font-size: 24px;
    @include themed() {
      color: t("textAlt");
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  &__main {
    &__item {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #767f9a;

      &__title {
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 12px;
      }

      &__disc {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
      }

      &__left {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        &__upload {
          display: flex;
          align-items: center;
          gap: 30px;
          flex-wrap: wrap;

          &__img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 1px solid rgb(129, 129, 129);
            @include flex-center;
            overflow: hidden;

            & img {
              object-fit: cover;
              width: 60px;
            }
          }

          &__btnCon {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        & h5 {
          font-weight: 700;
          font-size: 16px;
          margin-bottom: 10px;
        }

        & p {
          font-weight: 400;
          font-size: 14px;
        }
      }

      &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        flex-wrap: wrap;
      }
    }
  }

  &__biometric {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    &__left {
      max-width: 393px;

      & h5 {
        font-weight: 700;
        font-size: 20px;
        @include themed() {
          color: t("textAlt");
        }
        margin-bottom: 8px;
      }

      & p {
        font-size: 14px;
      }
    }
  }
}
