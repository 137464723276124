.paymentSuccess {
  @include themed() {
    background-color: t("bgAlt");
  }
  padding: 20px 30px;
  width: 500px;
  display: flex;
  //   grid-template-columns: 1fr;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: relative;

  &__closeBtn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  &__header {
    @include flex-center-space;
    font-weight: 700;
    font-size: 35px;
    line-height: 64px;
    @include themed() {
      color: t("textAlt");
    }
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 20px;
    text-align: center;

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      @include themed() {
        color: t("textAlt");
      }
      margin-bottom: 10px;
    }

    & h4 {
      font-weight: 700;
      font-size: 25px;
      line-height: 28px;
      color: $success;
      margin-bottom: 25px;
    }

    &__id {
      height: 48px;
      background: $bg-color-alt2;
      border-radius: 5px;
      @include flex-center;
    }
  }

  &__summary {
    // padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    &__main {
      width: 350px;
      &__method {
        @include flex-center;
        gap: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      &__item {
        @include flex-center-space;
        margin-bottom: 10px;
        color: #e2e4ed;
      }

      &__details {
        // color: $text-color-alt;
        padding-top: 10px;
        border-top: 1px solid rgba(177, 181, 195, 0.2);

        &__item {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          @include flex-center-space;
          margin-bottom: 10px;
        }
      }
    }
  }

  &__btnCon {
    display: flex;
    width: 100%;
    gap: 20px;
  }
}

@media screen and (max-width: 600px) {
  .paymentSuccess {
    padding: 30px 20px;
    margin: 0 2.5%;
    width: 95%;

    &__header {
      font-size: 20px;
    }

    &__summary {
      &__main {
        width: 95%;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .paymentSuccess {
    padding: 30px 20px;
    margin: 0 2.5%;
    width: 95%;

    &__header {
      font-size: 20px;
    }

    &__summary {
      &__main {
        width: 95%;
      }
    }

    &__btnCon {
      flex-direction: column;
    }
  }
}
